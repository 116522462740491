import { Box } from "@chakra-ui/react";
import React from "react";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  return (
    <Box display="flex" justifyContent="space-evenly" justifyItems="center">
      <NavLink to="./pokemon">Pokemon</NavLink>
      <NavLink to="./types">Types</NavLink>
      <NavLink to="./favorites">Favorites</NavLink>
    </Box>
  );
};

export { Navbar };
