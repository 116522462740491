import { Box, Button, Heading, Img, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AiOutlineStar } from "react-icons/ai";

const SinglePokemon = () => {
  const [data, setData] = useState([]);
  let url = localStorage.getItem("url");
  console.log(url);
  useEffect(() => {
    fetch("https://pokeapi.co/api/v2/pokemon/2")
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setData(res);
      })
      .catch((err) => console.log(err));
  }, []);
  console.log(data);
  return (
    <div>
      <Box>
        <Box border={"1px solid black"} padding="5px 25px" m="5px">
          <Heading>{data.name}</Heading>
          <AiOutlineStar
            onClick={(e) => {
              let da = JSON.parse(localStorage.getItem("favorites")) || [];
              da.push(data);
              localStorage.setItem("favorites", JSON.stringify(da));

              e.target.style.backgroundColor = "yellow";
            }}
          />

          <Text
            border="1px solid black"
            padding={"5px"}
            margin="5px"
            color={"red"}
          >
            ID: <span style={{ color: "black" }}>{data.id}</span>
          </Text>
          <Text
            border="1px solid black"
            padding={"5px"}
            margin="5px"
            color={"red"}
          >
            Base Experience:{" "}
            <span style={{ color: "black" }}>{data.base_experience}</span>
          </Text>
          <Text
            border="1px solid black"
            padding={"5px"}
            margin="5px"
            color={"red"}
          >
            HP:{" "}
            <span style={{ color: "black" }}>
              {Math.floor(Math.random() * 100)}
            </span>
          </Text>
          <Text
            border="1px solid black"
            padding={"5px"}
            margin="5px"
            color={"red"}
          >
            Attack Score:{" "}
            <span style={{ color: "black" }}>
              {Math.floor(Math.random() * 100)}
            </span>
          </Text>
          <Text
            border="1px solid black"
            padding={"5px"}
            margin="5px"
            color={"red"}
          >
            Defense Score:{" "}
            <span style={{ color: "black" }}>
              {Math.floor(Math.random() * 100)}
            </span>
          </Text>
          <Text
            border="1px solid black"
            padding={"5px"}
            margin="5px"
            color={"red"}
          >
            Special-Attack Score:{" "}
            <span style={{ color: "black" }}>
              {Math.floor(Math.random() * 100)}
            </span>
          </Text>
          <Text
            border="1px solid black"
            padding={"5px"}
            margin="5px"
            color={"red"}
          >
            Special-Defence Score:{" "}
            <span style={{ color: "black" }}>{data.base_experience}</span>
          </Text>
          <Text
            border="1px solid black"
            padding={"5px"}
            margin="5px"
            color={"red"}
          >
            Speed:{" "}
            <span style={{ color: "black" }}>{data.base_experience}</span>
          </Text>
          <Text
            border="1px solid black"
            padding={"5px"}
            margin="5px"
            color={"red"}
          >
            Abilities:{" "}
            {data.abilities?.map((el, index) => {
              return (
                <span style={{ color: "black" }} key={index}>
                  {el.ability.name}
                </span>
              );
            })}
          </Text>
          <Text
            border="1px solid black"
            padding={"5px"}
            margin="5px"
            color={"red"}
          >
            Moves:{" "}
            {data.moves?.map((el, index) => {
              return (
                <span style={{ color: "black" }} key={index}>
                  {el.move.name},{" "}
                </span>
              );
            })}
          </Text>
          <Text
            border="1px solid black"
            padding={"5px"}
            margin="5px"
            color={"red"}
          >
            Weight: <span style={{ color: "black" }}>{data.weight}</span>
          </Text>
          <Text
            border="1px solid black"
            padding={"5px"}
            margin="5px"
            color={"red"}
          >
            Height: <span style={{ color: "black" }}>{data.height}</span>
          </Text>
        </Box>
      </Box>
    </div>
  );
};

export default SinglePokemon;
