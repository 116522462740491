import logo from "./logo.svg";
import "./App.css";
import Allroutes from "./allRoutes/allroutes";
import { Navbar } from "./Conponents/navbar";

function App() {
  return (
    <div>
      <Navbar />

      <Allroutes />
    </div>
  );
}
export default App;
