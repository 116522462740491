import { Box, Button } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Pokemon = () => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(20);
  const [Totalcount, setTotalcount] = useState(100);

  useEffect(() => {
    fetch(`https://pokeapi.co/api/v2/pokemon?offset=${count}&limit=20`)
      .then((res) => res.json())
      .then((res) => {
        setData(res.results);
        setTotalcount(res.count);
      })
      .catch((err) => console.log(err));
  }, [count]);

  return (
    <div>
      <Box>
        <Button isDisabled={count <= 20} onClick={() => setCount(count - 20)}>
          prev
        </Button>
        <Button>{count / 20}</Button>
        <Button
          isDisabled={count >= Totalcount}
          onClick={() => setCount(count + 20)}
        >
          next
        </Button>
      </Box>
      <Box>
        {data.map((item, index) => {
          return (
            <Box
              onClick={() => {
                localStorage.setItem("url", item.url);
              }}
              border={"1px solid black"}
              padding="5px 25px"
              m="5px"
              key={index}
            >
              <Link to={`/pokemon/${item.name}`}>
                {item.name} <p>#{index + 1}</p>
              </Link>
            </Box>
          );
        })}
      </Box>
    </div>
  );
};

export default Pokemon;
