import { Box, Button } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Types = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetch(`https://pokeapi.co/api/v2/type`)
      .then((res) => res.json())
      .then((res) => {
        console.log(res.results);
        setData(res.results);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div>
      <Box>
        {data.map((item, index) => {
          return (
            <Box
              onClick={() => {
                localStorage.setItem("url2", item.url);
              }}
              border={"1px solid black"}
              padding="5px 25px"
              m="5px"
              key={index}
            >
              <Link to={`/types/${index + 1}`}>
                {item.name} <p>#{index + 1}</p>
              </Link>
            </Box>
          );
        })}
      </Box>
    </div>
  );
};

export default Types;
