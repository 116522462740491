import React from "react";
import { Route, Routes } from "react-router-dom";
import Favorites from "../pages/favorites";
import Pokemon from "../pages/pokemon ";
import SinglePokemon from "../pages/singlePokemon";
import SingleType from "../pages/singleType";
import Types from "../pages/types";

const Allroutes = () => {
  return (
    <Routes>
      <Route path="/pokemon" element={<Pokemon />} />
      <Route path="/pokemon/:pokemon_name" element={<SinglePokemon />} />
      <Route path="/types" element={<Types />} />
      <Route path="/favorites" element={<Favorites />} />
      <Route path="/types/:types_id" element={<SingleType />} />
    </Routes>
  );
};

export default Allroutes;
