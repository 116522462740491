import { Box, Button, Heading, Img, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AiOutlineStar } from "react-icons/ai";

const SingleType = () => {
  const [data, setData] = useState([]);
  let url = localStorage.getItem("url2");
  console.log(url);
  useEffect(() => {
    fetch("https://pokeapi.co/api/v2/pokemon/2")
      .then((res) => res.json())
      .then((res) => {
        setData(res);
      })
      .catch((err) => console.log(err));
  }, []);
  console.log(data);
  return (
    <div>
      <Box>
        <Box border={"1px solid black"} padding="5px 25px" m="5px">
          {data.types.map((el, index) => {
            return (
              <Box
                key={index}
                border="2px solid black"
                padding={"5px"}
                margin="5px"
              >
                <Text>{el.slot}</Text>
                <Text>{el.type.name}</Text>
              </Box>
            );
          })}
        </Box>
      </Box>
    </div>
  );
};

export default SingleType;
