import { Box, Button, Heading, Img, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AiOutlineStar } from "react-icons/ai";

const Favorites = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    let url = localStorage.getItem("favorites") || [];
    setData(url);
  }, []);
  console.log(data);
  return (
    <div>
      <Box>
        <Box border={"1px solid black"} padding="5px 25px" m="5px">
          {/* {data.map((el, index) => {
            return (
              <Box
                key={index}
                border="2px solid black"
                padding={"5px"}
                margin="5px"
              >
                <Text>{el.name}</Text>
              </Box>
            );
          })} */}
        </Box>
      </Box>
    </div>
  );
};

export default Favorites;
